import React, { useState, useEffect } from "react"
import * as queryString from "query-string"

import { Header } from "../../components"

const PasswordForgotConfirm = ({location}) => {
	const [url, setUrl] = useState('')
	useEffect(() => {
		const { email, code } = queryString.parse(location.search, { decode: false });
		setUrl(`newdancetv://auth/password-forgot-confirm/${email}/${code}`)
	}, [])
	

	return (
		<>
			<Header />
			<div className="layout">
				<h1>Reset password</h1>
				<p>Please use the button below to reset your password in the app.</p>
				<p style={{ opacity: .5 }}>Make sure you have the New Dance TV app installed on this device.</p>
				<a style={{ display: 'inline-block', textDecoration: 'none' }} href={url} className='primaryBtn'>Open app</a>
			</div>
		</>
	)
}

export default PasswordForgotConfirm
